//
// Base
//

body {
  padding-top: 10px;

  @media (min-width: $screen-md-min) {
    padding-top: 41px;
  }
}

dt,
label {
  font-family: inherit;
  font-weight: normal;
}

h1,
h2 {
  margin-top: 0;
  margin-bottom: 25px;
}

h1, h2, h3, h4, h5 {
  line-height: 1.3;

  &.compact {
    margin: 0;
  }
}

strong,
b {
  font-weight: normal;
  font-family: $font-bold;
}

a.more,
a.toggle {
  font-family: $font-bold;

  &.block {
    margin-top: $adhoc-content-margin-bottom/2;
    display: block;
  }
}

p {
  a {
    text-decoration: underline;
  }
}

.content {
  @include clearfix;
  margin-bottom: $adhoc-content-margin-bottom;
}

//
// Bootstrap customizations
//

.well {
  border-radius: 0;
  box-shadow: none;
  border: none;
  background-color: $color-dark-gray;
  color: $color-white;
  font-size: 16px;

  &.transparent {
    background-color: transparentize($color-dark-gray, 0.3);
  }

  a {
    color: inherit;
  }
}

.btn-default {
  background-color: $color-dark-gray;
  border: none;
  border-radius: 0;
  color: $color-white;

  &:hover {
    background-color: $color-dark-gray;
    color: $color-white;
  }
}

.btn-small {
  @extend .btn-default;
  font-size: $font-size-small;
}

.carousel-control {
  text-shadow: none;
  opacity: 1;

  &.left, &.right {
    background: none;
  }

  &:hover {
    color: $color-dark-gray;
  }
}

.carousel-caption {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: auto;
  padding: 0;
  text-align: left;
}

.carousel-indicators li {
  border-radius: 0;
}

.carousel-inner > .item {
  transition: transform 0.7s ease-in-out;
}

.carousel-fade {
  .carousel-inner {
    .item {
      opacity: 0;
      transition-property: opacity;
    }

    .active {
      opacity: 1;
    }

    .active.left,
    .active.right {
      left: 0;
      opacity: 0;
      z-index: 1;
    }

    .next.left,
    .prev.right {
      opacity: 1;
    }
  }

  .carousel-control {
    z-index: 2;
  }
}

.dropdown-menu {
  border-radius: 0;
  margin: 0;
  padding: 0;
}

.collapsing {
  @include transition-duration(.35s);
}

//
// Header
//

.header {

  @media (max-width: $screen-sm-max) {
    padding-bottom: $adhoc-content-margin-bottom/2;
  }

  .header__logo {
    @include make-md-column(2);
    @include make-md-column-offset(0);

    img {
      width: auto;
      height: 58px;
    }
  }

  .header__nav {
    $logo-height: 58px;
    $header-font-size: $font-size-large;

    // NOTE: Sort from small to large.
    @include make-md-column(9);
    @include make-md-column-offset(1);
    @include make-lg-column(9);
    @include make-lg-column-offset(1);

    > ul {
      > li {
        &.active > a {
          font-family: $font-bold;
        }

        ul > li a:hover {
          background-color: inherit;
        }
      }
    }

    .navbar-toggle {
      border-color: $color-medium-gray;

      @media (max-width: $screen-sm-max) {
        position: absolute;
        top: -65px;
        right: 0;
      }

      .icon-bar {
        background-color: $color-black;
      }
    }

    // Smartphones & Tablets (portrait)
    @media (max-width: $screen-sm-max) {
      ul.list-inline {
        display: none;
        border-bottom: 1px solid $color-light-gray;

        &.collapsing,
        &.collapse.in {
          display: block;
        }

        > li {
          display: block;
          padding-left: 10px;

          &:first-child {
            padding-top: 10px;
          }

          &:last-child {
            padding-bottom: 10px;
          }

          a {
            text-decoration: none;
            color: inherit;
          }
        }
      }

      ul.dropdown-menu {
        top: inherit;
        width: 97%;
        margin-left: 5px;
        margin-top: 5px;
        padding: 10px;

        li a {
          padding: 0;
        }

        li.active a {
          color: inherit;
          background: none;
          font-family: $font-bold;
        }
      }

      ul.list-inline,
      ul.dropdown-menu {
        font-size: 18px;
      }
    }

    // Tablets (landscape) & Desktop
    @media (min-width: $screen-md-min) {
      a,
      a:hover {
        text-decoration: none;
      }

      // First level
      > ul {
        > li {
          position: relative;
          font-size: $header-font-size;
          line-height: $logo-height * 2 - $header-font-size;
          margin-right: 20px;

          > a:after {
            position: absolute;
            bottom: 30px;
            left: 0;
            right: 0;
            height: 2px;
            background-color: $color-light-gray;
            content: "";
            visibility: hidden;
          }

          &.active a:after {
            background-color: $color-medium-gray;
          }

          &.active:not(.open) a:after,
          &:hover:not(.open) a:after,
          &.hover:not(.open) a:after {
            visibility: visible;
          }
        }
      }

      // Second level
      > ul > li {
        @include adhoc-sub-menu;

        > ul {
          &.dropdown-menu {
            top: $logo-height + 10px;
            margin-left: -35px;
            width: 200px;

            .active > a {
              color: inherit;
              background-color: inherit;
            }
          }

          > li {
            margin: 0 20px;
          }
        }
      }
    }
  }

  .mega-content {
    display: none;
  }

  .header__locales {
    position: absolute;

    @media (max-width: $screen-sm-max) {
      top: 50px;
      right: 6px;
      font-size: $font-size-small+3px;
    }

    @media (min-width: $screen-md-min) {
      top: 10px;
      right: 10px;
      font-size: $font-size-small+1px;
    }

    div,
    ul {
      display: inline-block;
    }

    div {
      color: $color-medium-gray;
      padding-right: 5px;
    }

    li {
      padding: 0;

      &:not(:last-child):after {
        content: "|"
      }

      &.current {
        font-family: $font-bold;
      }

      a:link {
        text-decoration: none;
        padding: 10px 1px;
      }
    }
  }
}

//
// Footer
//

.footer {
  background-color: $color-dark-gray;
  color: $color-white;
  line-height: 1.8;

  .footer__nav {
    @include make-md-column(2);
    padding-top: $adhoc-content-padding-top;

    &:first-child {
      @include make-md-column-offset(1);
    }

    &.narrow {
      @include make-md-column(2);
    }

    a, a:hover, a:active {
      color: $color-white;
    }

    h5 {
      border-top: 1px solid $color-white;
      padding-top: $adhoc-content-padding-top;
      font-family: $font-bold;
    }
  }
}

@media (max-width: $screen-sm-max) {
  h1 {
    font-size: $font-size-h1 * 0.6;

    &:first-of-type {
      margin-top: 0;
    }
  }

  h2 {
    font-size: $font-size-h2 * 0.6;
  }

  h3 {
    font-size: $font-size-h3 * 0.6;
  }

  // Hide some elements on small devices
  .callout,
  .timeline,
  .footer {
    display: none;
  }
}

//
// Misc
//
.product-features {
  &.features .caption {
    p.feature {
      min-height: 77px !important;
    }

    img:nth-of-type(2n+1) {
      clear: left;
    }
  }

  &.awards .caption {
    p.feature {
      display: none;
    }

    img {
      float: left;
      width: 50% !important;
    }
  }
}

.product-description.desc-img {
  margin-bottom: 25px;

  .desc-img__desc.wide {
    p.well {
      margin-top: 10px;
    }
  }
}

.nav-overlay {
  display: none;
  position: fixed;
  top: 50px;
  margin-left: -40px;

  a:link {
    text-decoration: none;
    padding: 15px;
    background-color: #eee;
    outline: 0;
  }
}

.form-inline {
  margin-bottom: $adhoc-content-margin-bottom;
}
