.store-locator {
  input, select {
    margin: 0 3px;
  }

  .store-locator__desc {
    @include adhoc-content-100;
  }

  .store-locator__results {
    display: none;
  }

  .store-locator__map {
    @include make-md-column(6);
    @include make-md-column-offset(1);
    margin-top: 40px;

    #map-canvas {
      width: 100%;
      height: 420px;
    }
  }

  .store-locator__legend {
    @include make-md-column(6);
    @include make-md-column-offset(1);

    img {
      position: relative;
      top: -2px;
      float: left;
      width: 32px;
      margin-right: 10px;
    }

    p {
      margin-top: 0;
      float: left;
      line-height: 32px;
    }
  }

  .store-locator__stores {
    @include make-md-column(5);
    padding-left: 0;
    margin-top: 40px;
    position: relative;

    ol {
      display: block;
      list-style-type: none;
      counter-reset:sectioncounter;

      li {
        display: block;
        margin: auto auto 30px -30px;
        padding-left: 50px;
        padding-bottom: 10px;
        border-bottom: solid 2px;
        background-repeat: no-repeat;
        background-size: 32px 32px;
        background-position: 8px 40px;
        overflow: visible;
        @include clearfix;

        &.highlighted {
          background-image: url('/_Resources/Static/Packages/Adhoc.AdhocDesignDe/Images/layout/marker_highlighted.png')
        }

        dl {
          padding: 0;
          display: block;
          width: 45%;
          float: left;

          dd:first-child {
            font-size: 17px;
            font-weight: bold;
            width: 200%;
          }
        }

        .store-locator__image {
          display: block;
          float: right;
          width: 38%;
          padding: 35px 5px;
        }
      }

      li:before {
        content: counter(sectioncounter) " ";
        counter-increment: sectioncounter;
        background-color: #000;
        color: #fff;
        display: block;
        position: absolute;
        left: 20px;
        width: 30px;
        text-align: center;
      }
    }
  }
}
