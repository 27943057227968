@mixin adhoc-border {
  border-style: solid;
  border-color: $color-medium-gray;
  border-width: 0;
  border-top-width: 2px;
}

@mixin adhoc-content-margin {
  margin-bottom: $adhoc-content-margin-bottom;
}

@mixin adhoc-actions {
  .actions {
    // Use outer grid
    @include make-sm-outer-column(2, 6);
    @include make-md-outer-column(2, 5);

    .btn {
      display: block;
      margin-top: $adhoc-content-padding-top;
      text-align: left;
      text-indent: 6%;
      background-image: url("../Images/layout/arrow_right_white.svg");
      background-size: contain;
      background-repeat: no-repeat;

      &:hover,
      &:focus,
      &.focus {
        color: $color-white;
      }
    }
  }
}

@mixin adhoc-sub-menu {
  > ul {
    list-style: none;

    > li {
      border-style: solid;
      border-color: $color-light-gray;
      border-width: 0;
      border-bottom-width: 1px;
      line-height: 3;
    }

    > li a {
      line-height: inherit;

      &:hover,
      &:focus {
        text-decoration: none;
        background-color: transparent;
      }
    }

    > li:last-child {
      margin-bottom: 1em;
    }
  }
}

@mixin adhoc-content {
  padding-top: $adhoc-content-padding-top;

  @media (min-width: $screen-md-min) {
    @include adhoc-border;
  }

  p, .well {
    &:first-of-type {
      margin-top: $adhoc-content-margin-bottom/2;
    }
  }

  .well p:first-of-type {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &.large {
    font-size: $font-size-large;
  }

  &.whitespace {
    padding-top: $adhoc-content-padding-top * 2;

    img + img {
      padding-top: $adhoc-content-padding-top * 2;
    }
  }

  img {
    max-width: 100%;
  }

  figure img {
    width: auto !important;
    height: auto !important;
  }

  img + img {
    padding-top: $adhoc-content-padding-top;
  }

  dl {
    dt {
      float: left;
      width: 100px;
      clear: left;
    }

    dd {
      @include clearfix;
      @include text-overflow;
    }
  }
}

@mixin adhoc-content-40 {
  @include adhoc-content;
  @include make-sm-column(5);
  @include make-md-column(4);
}

@mixin adhoc-content-50 {
  @include adhoc-content;
  @include make-sm-column(6);
  @include make-md-column(5);
}

@mixin adhoc-content-60 {
  @include adhoc-content;
  @include make-sm-column(12);
  @include make-md-column(6);
  @include adhoc-img-caption;
}

@mixin adhoc-img-caption {
  .caption {
    &.horizontal {
      p, img {
        margin-top: 25px;
      }

      img {
        @include make-sm-outer-column(2,12);
        @include make-md-outer-column(1,6);
      }

      p {
        @include make-sm-outer-column(4,12);
        @include make-md-outer-column(2,6);
      }
    }
  }
}

@mixin adhoc-content-100 {
  @include adhoc-content;
  @include make-sm-column(12);
  @include make-md-column(11);
  @include make-md-column-offset(1);
  padding-top: $adhoc-content-padding-top;

  @media (min-width: $screen-md-min) {
    @include adhoc-border;
  }

  &.large {
    font-size: $font-size-large;
  }

  &.whitespace {
    padding-top: $adhoc-content-padding-top * 2;

    img + img {
      padding-top: $adhoc-content-padding-top * 2;
    }
  }
}
