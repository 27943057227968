.mega-menu {
  $height: 525px;

  display: none;
  height: 0;
  border-color: transparent;
  background-repeat: no-repeat;
  background-position: right top;
  background-size: auto $height;

  &[data-container="schneidwerk"] {
    background-image: url("../Images/layout/mega/schneidwerk.jpg");
  }

  &[data-container="manuelle-muehlen"] {
    background-image: url("../Images/layout/mega/manuelle-muehlen.jpg");
  }

  &[data-container="elektrische-muehlen"] {
    background-image: url("../Images/layout/mega/elektrische-muehlen.jpg");
  }

  &[data-container="kueche"] {
    background-image: url("../Images/layout/mega/kueche.jpg");
  }

  &[data-container="living"] {
    background-image: url("../Images/layout/mega/living.jpg");
  }

  &[data-container="schuhbecks"] {
    background-image: url("../Images/layout/mega/schuhbecks.jpg");
  }

  &[data-container="tee"] {
    background-image: url("../Images/layout/mega/tee.jpg");
  }

  &[data-container="kaffee"] {
    background-image: url("../Images/layout/mega/kaffee.jpg");
  }

  &[data-container="wein"] {
    background-image: url("../Images/layout/mega/wein.jpg");
  }

  &[data-container="dots-edition"] {
    background-image: url("../Images/layout/mega/dots-edition.jpg");
  }

  &[data-container="safety-touch"] {
    background-image: url("../Images/layout/mega/safety-touch.jpg");
  }

  &.collapsing {
    @include transition-property(height, visibility, border-color);
  }

  &.collapsing, &.collapse.in {
    @include adhoc-border;
  }

  &.collapse.in {
    height: $height;
  }

  .mega-menu__menu {
    @include make-md-column(2);
    @include make-md-column-offset(1);
    @include adhoc-sub-menu;

    ul {
      padding-left: 0;

      a.active {
        font-family: $font-bold;
      }
    }
  }

  .mega-menu__content {
    @include make-md-column(9);
    height: $height - 2px;

    [data-content] {
      display: none;
    }

    .caption {
      position: absolute;
      right: 0;
      bottom: 85px;
      width: 420px;

      > a {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 30px;
        line-height: 30px;
        color: $color-white;
        text-align: center;
        text-decoration: none;
        background-color: transparentize($color-dark-gray, 0.1);
        cursor: pointer;
      }
    }

    &.confirm {
      .caption {
        padding-bottom: 30px;

        > a {
          display: block;
        }
      }
    }
  }
}
