//
// Bootstrap
//
// http://getbootstrap.com/customize/#less-variables
// https://github.com/twbs/bootstrap-sass/blob/v3.3.1/assets/stylesheets/bootstrap/_variables.scss
//

// Fix path to fonts
$icon-font-path: "fonts/";

// Decrease gutter width from default 30px, increase total width to match default.
$grid-gutter-width: 16px;
$container-large-desktop: (1154px + $grid-gutter-width) !default;
$container-desktop: (954px + $grid-gutter-width) !default;
$container-tablet: (734px + $grid-gutter-width) !default;

// Responsiveness
$grid-float-breakpoint: 992px;  // Point at which navbar becomes uncollapsed
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1);

// Fonts & Colors
$font-family-sans-serif: "Arial W01 Rounded Light", "Helvetica Neue", Helvetica, sans-serif;
$font-size-small: 11px;
$font-size-base: 15px;
$font-size-large: 17px;
$font-size-h2: ceil($font-size-base * 1.7);
$line-height-base: 1.6;
$brand-primary: #000;
$font-light: "Arial W01 Rounded Light";
$font-bold: "Arial W01 Rounded Bold";
$color-white: #ffffff;
$color-black: #000000;
$color-dark-gray: rgb(37,39,41);
$color-medium-gray: rgb(93,93,93);
$color-light-gray: rgb(202,202,202);

// Components
$carousel-control-color: $color-light-gray;
$carousel-caption-color: inherit;
$carousel-text-shadow: none;
$carousel-indicator-border-color: $color-dark-gray;
$carousel-indicator-active-bg: $color-dark-gray;

// Adhoc
$adhoc-padding: 30px;
$adhoc-content-margin-bottom: 50px;
$adhoc-content-padding-top: $adhoc-padding;
