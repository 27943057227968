.desc {
  .desc__content {
    @include adhoc-content-100;

    p {
      max-width: 70%;
    }
  }
}

.img-desc,
.desc-img {
  h2, h3, h4, h5 {
    &:first-child {
      margin-top: 0;
    }
  }
}

.desc-img {
  @include adhoc-content-margin;
  @include adhoc-actions;

  .desc-img__desc {
    @include adhoc-content-50;
    @include make-md-column-offset(1);

    &.narrow {
      @include adhoc-content-40;
    }

    &.wide {
      @include adhoc-content-60;
      margin-left: 0;
    }
  }

  .desc-img__img {
    @include adhoc-content-50;
  }
}

.img-desc {
  @include adhoc-content-margin;
  @include adhoc-actions;

  .img-desc__img {
    @include adhoc-content-50;
    @include make-md-column-offset(1);
  }

  .img-desc__desc {
    @include adhoc-content-50;
  }
}
