.timeline {
  $height: 600px;
  $timeline-y: $height - 65px;
  $timeline-color-light: darken($color-light-gray, 30%);
  $timeline-color-dark: $color-black;

  position: relative;

  .timeline__timeline {
    position: absolute;
    left: 0;
    right: 0;
    top: $timeline-y;
    border-top: 2px solid $timeline-color-light;
  }

  .timeline__content {
    @include make-md-column(10);
    @include make-md-column-offset(1);
    background-repeat: no-repeat;
    background-position: right top;
    background-size: auto $height;
    margin-bottom: $adhoc-padding * 2;
    height: $height;

    &[data-year="1996"] {
      background-image: url("../Images/pages/unternehmen/geschichte/1996/1996.jpg");
    }

    &[data-year="1997"] {
      background-image: url("../Images/pages/unternehmen/geschichte/1997/1997.jpg");
      background-position: right -65px;
    }

    &[data-year="1998"] {
      background-image: url("../Images/pages/unternehmen/geschichte/1998/1998.jpg");
    }

    &[data-year="2004"] {
      background-image: url("../Images/pages/unternehmen/geschichte/2004/2004.jpg");
    }

    &[data-year="2006"] {
      background-image: url("../Images/pages/unternehmen/geschichte/2006/2006.jpg");
    }

    &[data-year="2007"] {
      background-image: url("../Images/pages/unternehmen/geschichte/2007/2007.jpg");
    }

    &[data-year="2008"] {
      background-image: url("../Images/pages/unternehmen/geschichte/2008/2008.jpg");
    }

    &[data-year="2010"] {
      background-image: url("../Images/pages/unternehmen/geschichte/2010/2010.jpg");
    }

    &[data-year="2012"] {
      background-image: url("../Images/pages/unternehmen/geschichte/2012/2012.jpg");
    }

    &[data-year="2013"] {
      background-image: url("../Images/pages/unternehmen/geschichte/2013/2013.jpg");
    }

    &[data-year="2014"] {
      background-image: url("../Images/pages/unternehmen/geschichte/2014/2014.jpg");
    }

    &[data-year="2015"] {
      background-image: url("../Images/pages/unternehmen/geschichte/2015/2015.jpg");
    }

    &[data-year="2016"] {
      background-image: url("../Images/pages/unternehmen/geschichte/2016/2016.jpg");
    }

    .timeline__headline {
      @include make-md-outer-column(5, 10);
    }

    .timeline__years {
      position: absolute;
      right: 0;
      top: $timeline-y;
      width: 100%;
      padding: 0 50px;
      border-top: 2px solid $timeline-color-light;

      ul {
        position: relative;
        list-style-type: none;
        margin: 0 40px;
      }

      li {
        @function year-width($year) {
          $first-year: 1996;
          $last-year: 2015;
          $years: $year - $first-year;
          $width: 1 / ($last-year - $first-year);
          @return percentage($years * $width);
        }

        display: inline-block;
        position: absolute;
        margin-left: -40px;
        cursor: pointer;

        &[data-year="2016"] { left: year-width(2016) };
        &[data-year="2015"] { left: year-width(2015) };
        &[data-year="2014"] { left: year-width(2014) };
        &[data-year="2013"] { left: year-width(2013) };
        &[data-year="2012"] { left: year-width(2012) };
        &[data-year="2010"] { left: year-width(2010) };
        &[data-year="2008"] { left: year-width(2008) };
        &[data-year="2007"] { left: year-width(2007) };
        &[data-year="2006"] { left: year-width(2006) };
        &[data-year="2004"] { left: year-width(2004) };
        &[data-year="1998"] { left: year-width(1998) };
        &[data-year="1997"] { left: year-width(1997) };
        &[data-year="1996"] { left: year-width(1996) };

        &.hover {
          border-bottom: 2px solid $timeline-color-light;
        }

        &.active {
          border-bottom: 2px solid $timeline-color-dark;
          font-family: $font-bold;
        }

        &:before {
          display: block;
          content: '■';
          color: $timeline-color-light;
          text-align: center;
          font-size: 30px;
          line-height: 22px;
          margin-top: -9px;
          z-index: 20;
        }

        &.active:before {
          color: $timeline-color-dark;
        }

        .caption {
          position: absolute;
          top: -275px;
          left: -130px;
          width: 300px;
          height: 255px;
          font-family: $font-light;
          display: none;
        }
      }
    }
  }
}
