.filter-menu {
  margin-bottom: 2em;
  font-size: 12px;

  .filter-menu__property {
    @include make-xs-column(6);
    @include make-md-column(1.7);
    @include make-lg-column(1.5);

    @media (max-width: $screen-xs-max) {
      float: none;
      margin: auto;
    }

    &.offset {
      @include make-md-column-offset(1);
    }

    label {
      font-family: $font-light;
      font-weight: normal;
    }
  }

  .filter-menu__caption {
    @include make-md-column(1);
    line-height: 24px;
    padding-top: 24px;

    @media (min-width: $screen-lg-min) {
      padding-left: 20px;
    }

    @media (max-width: $screen-md-min) {
      display: none;
    }

    label {
      font-family: $font-light;
      font-weight: normal;
    }
  }
}
