$callout-height: 521px;

.callout {
  height: $callout-height;

  .carousel-inner {
    @include adhoc-border;
    border-bottom-width: 2px;
  }

  .gradient {
    background: rgba(166,159,166,1);
    background: -moz-linear-gradient(-45deg, rgba(166,159,166,1) 0%, rgba(246,246,246,0.96) 63%, rgba(255,255,255,0.94) 82%, rgba(255,255,255,0.94) 89%);
    background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(166,159,166,1)), color-stop(63%, rgba(246,246,246,0.96)), color-stop(82%, rgba(255,255,255,0.94)), color-stop(89%, rgba(255,255,255,0.94)));
    background: -webkit-linear-gradient(-45deg, rgba(166,159,166,1) 0%, rgba(246,246,246,0.96) 63%, rgba(255,255,255,0.94) 82%, rgba(255,255,255,0.94) 89%);
    background: -o-linear-gradient(-45deg, rgba(166,159,166,1) 0%, rgba(246,246,246,0.96) 63%, rgba(255,255,255,0.94) 82%, rgba(255,255,255,0.94) 89%);
    background: -ms-linear-gradient(-45deg, rgba(166,159,166,1) 0%, rgba(246,246,246,0.96) 63%, rgba(255,255,255,0.94) 82%, rgba(255,255,255,0.94) 89%);
    background: linear-gradient(135deg, rgba(166,159,166,1) 0%, rgba(246,246,246,0.96) 63%, rgba(255,255,255,0.94) 82%, rgba(255,255,255,0.94) 89%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a69fa6', endColorstr='#ffffff', GradientType=1 );
  }

  .callout__image {
    height: $callout-height;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right bottom;
  }

  .callout__headline {
    @include make-xs-column(3);
    @include make-md-column-offset(1);
    margin-top: 100px;

    h1 {
      font-size: 75px;
    }
  }
}
