.products {
  @include adhoc-content-margin;

  // Instead of offsetting every first content column indent
  // using relative positioning (but only for >= medium screens).
  /*
  @media (min-width: $screen-md-min) {
    .content-offset {
      position: relative;
      left: 97px;
    }
  }
  */

  .products__headline {
    @include make-md-column(6);
    @include make-md-column-offset(1);
    margin-top: 30px;
  }

  .products__message {
    @include make-md-column(11);
    @include make-md-column-offset(1);
    display: none;

    a {
      text-decoration: underline;
    }
  }

  .products__item {
    @include make-xs-column(12);
    @include make-sm-column(6);
    @include make-md-column(2);

    @media (max-width: $screen-xs-max) {
      clear: both;

      img {
        display: block;
        margin: auto;
      }
    }

    &.offset {
      @include make-md-column-offset(1);
    }

    &.wide {
      @include make-sm-outer-column(3, 7);
      @include make-md-outer-column(2, 6);
    }

    &:hover a {
      text-decoration: underline;
    }

    .products.separator .row:first-child & {
      @include adhoc-border;
      padding-top: $adhoc-content-padding-top;
    }

    .caption {
      height: 100px;
      padding: 6px ($grid-gutter-width / 2);
      border-top: 1px solid $color-light-gray;
      text-align: center;
    }

    img {
      max-width: 100%;
      display: block;
    }
  }
}
