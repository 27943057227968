$start-num: 0;

.whitespace{
  a{
    font-weight: bold;
  }
}
.page-foot{
  @include adhoc-content-100;
  ol{
    @include make-md-column(3);
    @include make-md-column-offset(4);
    display: block;
    list-style-type: none;
    counter-reset: sectioncounter $start-num;
    text-align: center;
    li{
      display: block;
      float: left;
    }
    li:before {
      content: counter(sectioncounter) " ";
      counter-increment: sectioncounter;
      padding-left: 2px;
    }
    li:after{
      content:"|";
    }
    li:last-child:before{
      content: none;
    }
    li:last-child:after{
      content: none;
    }
  }
}

@media(min-width: 992px){
  .page-foot{
    @include adhoc-border;
  }
}