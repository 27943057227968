.list-in-content{
 margin-top: 25px;
 padding-left: 20px;
}

.phi-desc-img__desc{
  @include make-sm-column(6);
  @include make-xs-column(12);
  @include make-md-column-offset(1);
  @include make-md-column(5);
  @include make-lg-column(3);
  display: block;
  float: left;
  z-index: 200;
  padding-top: 20px;
}

.phi-desc-img__img{
  @include adhoc-content-50;
  @include make-xs-column(12);
  @include make-md-column(5);
  @include make-lg-column(7);
  padding-top: 20px;
  z-index: 100;
  display: block;
  float: left;
}

@media(min-width: 992px){
  .phi-desc-img__desc{
    @include adhoc-border;
    h1{
      width: 800px;
      z-index: 200;
    }
  }
  .phi-desc-img__img{
    @include adhoc-border;
    padding-top: 70px;
  }
}

@media(min-width: 1200px){
  .phi-desc-img__img{
    padding-top: 0;
  }
}