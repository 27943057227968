.contact-form,
.newsletter-form {
  .contact-form__contact,
  .newsletter-form__form {
    @include adhoc-content-100;
  }

  .contact-form__confirmation,
  .newsletter-form__confirmation {
    @include adhoc-content-100;
    padding-bottom: 200px;
    display: none;
  }

  .contact-form__form,
  .newsletter-form__form {
    @include make-md-column(5);
    padding: 0;

    .form-control, textarea {
      margin: 10px auto;
      background-color: #fafafa;
      color: rgb(38,39,41);
      border-radius: 0;
      margin: 10px auto;
    }

    p {
      margin-top: 0;
    }

    .supplement {
      @include clearfix;

      p {
        margin-top: 0;
        float: left;
        width: 100px;
      }

      label {
        margin-right: 10px;
      }
    }

    .g-recaptcha {
      margin-top: 25px;
    }

    .required {
      font-size: 12px;
      color: $color-medium-gray;
    }

    input[type="submit"] {
      margin-top: 5px;
    }
  }
}
